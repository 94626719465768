<script>
import {IncludeAreaService} from "@/modules/client/services/includeAreaService";

export default {
  name: "IncludeArea",
  props: {
    code: {
      required: true,
      type: String
    }
  },
  data: ()=>({
    includeArea: null
  }),
  async mounted() {
    this.includeArea = await IncludeAreaService.get(this.$props.code)
  }
}
</script>

<template>
  <div>
    <slot v-bind="{text: includeArea}">
      <div v-html="includeArea"></div>
    </slot>
  </div>
</template>

<style scoped lang="scss">

</style>