<template>
  <div class="m-3">
    <div class="row g-3">
      <div class="col-12 col-md-6">
        <v-card elevation="0">
          <v-card-text>
            Личный кабинет:
            {{
              [
                user.client.surname,
                user.client.name,
                user.client.lastname,
              ].join(" ")
            }}
            <br/>
            Текущий баланс: {{client.balance?.toLocaleString() || '0'}}<br>
            Депозит: {{client.deposit?.toLocaleString() || '0'}}<br>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-12 col-md-6">
        <RouterLink :to="{ name: 'ClientRent' }">
          <v-card elevation="0" color="primary">
            <v-card-text class="white--text">
              <template v-if="getRentHistory">
                <template v-if="getRentHistory.length === 0">
                  Заявок на прокат или текущих прокатов нет.
                </template>
                <template v-else>
                  Имеется {{ getRentHistory.length }} заявок на прокат
                </template>
              </template>
              <template v-else> Загружаем историю проката...</template>
            </v-card-text>
          </v-card>
        </RouterLink>
      </div>
      <div class="col-12 col-md-6">
        <v-card elevation="1">
          <v-card-title>Авто сообщения:</v-card-title>
        </v-card>
      </div>
      <div class="col-12 col-md-6">

        <v-card elevation="1">
          <v-card-title>
            <include-area code="client-dashboard-title">
            </include-area>
          </v-card-title>
          <v-card-text>
            <include-area code="client-dashboard">
            </include-area>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import IncludeArea from "@/modules/client/components/IncludeArea.vue";

export default {
  name: "ClientDashboard",
  computed: {
    ...mapGetters("user", ["user","client"]),
    ...mapGetters("rent", ["getRentHistory"]),
  },
  components: {IncludeArea},
  methods: {
    ...mapActions("rent", ["loadRents"]),
  },
  mounted() {
    this.loadRents();
  },
};
</script>

<style scoped></style>
